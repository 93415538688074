
import React from 'react'
// import { Link } from 'gatsby'
// import PropTypes from "prop-types"
import Layout from '../components/layout'
import Footer from '../components/Footer'
import withLocation from "../components/withLocation"
import MyFrame from "../components/PumbForm"
// import BackLink from "../components/BackLink"

const SecondPage = ({ search }) => {
  const { tokref,trhash,bid } = search
  
  console.log(trhash)
  const backLink = trhash
  return(
  <Layout>
    <header id="header">
    <div className="offerta-logo">
        <span className="icon fa-handshake-o fa-2x "></span>
    </div>

    </header>
    <noscript>
      Your Browser Not support JS. Open link in another Browser.
    </noscript>
    <MyFrame srcURL={tokref} backLink={backLink} bid={bid} />   
    <div style={{"textAlign":"center"}}>
    {/* <BackLink backhref={trhash} /> */}
      {/* <a href={baclLink}> <strong>Повернутись до транзакції</strong> </a> */}
    </div>
    
    <Footer/>
  </Layout>
  )
}

export default withLocation(SecondPage)
