import React, { Component } from "react"
class MyFrame extends Component {
  constructor(props) {
    super(props)
    this.launchChecker()
  }
  
  launchChecker() {
    console.log('Back URL' + this.props.backLink )
    const requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/check-token-web?id=${this.props.bid}`
    setInterval(function(){
      fetch(requestUrl, {
        method: 'GET', 
        mode: 'cors', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json();
      }).then((data) => {
        if (data.status === 'finish'){
          window.location = this.props.backLink
        }
      });
    }.bind(this), 5000)
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.launchChecker)
  }
  componentDidMount(){
    document.getElementById('payment-form').setAttribute("src", decodeURI(this.props.srcURL) );
    window.addEventListener('beforeunload', this.launchChecker)
  }

  render(){
    return(
    <iframe
      id="payment-form"
      src=""
      title="Token Generation Frame"
      // sandbox="allow-forms allow-scripts allow-top-navigation"
      sandbox="allow-scripts allow-forms allow-orientation-lock allow-top-navigation"
      width="550px"
      height="450px"
      // style = {{"overflow":"hidden"}}
    />
    )
  }
}
export default MyFrame